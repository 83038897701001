@import "init"

html
  height: 100%
  background: #fff

body
  min-height: 100%
  display: flex
  flex-direction: column
  overflow-x: hidden
  min-width: 320px
  background-color: #fafafa

#root
  flex: 1 1
  display: flex
  flex-direction: column

.AuthPage
  margin: auto

.MuiLinearProgress-root
  position: fixed !important
  left: 0
  right: 0
  top: 0
  z-index: 1500

.CodeMirror
  font-size: 12px
  height: 600px
  border: 1px solid #ddd
  border-radius: 2px

.MuiBreadcrumbs-li
  > a
    text-decoration: none
    &,
    &:hover,
    &:active,
    &:visited
      color: inherit

.infinity.infinity_hidden
  > div
    display: none
